import * as pdfjsLib from 'pdfjs-dist';
//export const pdfjs = await import('pdfjs-dist/build/pdf');
export const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.min.mjs');

//pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.min.js`;
//pdfjs.GlobalWorkerOptions.workerSrc = './pdfjs-dist/build/pdf.worker.mjs';



export const extractTextFromPDF = async (pdfArrayBuffer) => {
  const loadingTask = pdfjsLib.getDocument({ data: pdfArrayBuffer });
  const pdfDocument = await loadingTask.promise;
  const numPages = pdfDocument.numPages;

  let extractedText = 'entrealmenos';
  for (let i = 1; i <= numPages; i++) {
    const page = await pdfDocument.getPage(i);
    const textContent = await page.getTextContent();
    const items = textContent.items;

    for (const item of items) {
      const str = item.str;
      extractedText += str + '\n'; // Add newline for clarity
    }
  }

  return extractedText.trim(); // Remove leading/trailing whitespace
};
