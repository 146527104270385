import React, { useEffect, useState } from "react";
import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import { auth, registerUser, userExists, getCurrentUser } from "../../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import { pathRoutes } from "../../routes/PathRoutes";
import "./LoginView.css"; // Importa el archivo CSS
import ChatbotImage from "../../assets/chatbotImage.png"

export default function LoginView() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(null);
  const [state, setCurrentState] = useState(0);

  /* State 0: inicializado
     State 1: loading
     State 2: login completo
     State 3: login pero sin registro
     State 4: no hay nadie logueado
   */

  useEffect(() => {
    setCurrentState(1);
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const isRegistered = await userExists(user.uid);
        if (isRegistered) {
          console.log("userregistered", user.uid);
          const userId = user.uid;
          navigate(pathRoutes.chatBot, { state: { userId } });
          setCurrentState(2);
        } else {
          const registrar = await registerUser(user);
          if (registrar != null) {
            const userId = user.uid;
            navigate(pathRoutes.chatBot, { state: { userId } });
          }
          setCurrentState(3);
        }
      } else {
        setCurrentState(4);
        console.log("Nadie está autenticado...");
      }
    });
  }, [navigate]);

  async function handleOnClick() {
    const googleProvider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const userID= result.providerId;
      console.log(`Usuario autenticado: ${user.displayName}`);
      navigate(pathRoutes.chatBot, { state: { userID} });
      setCurrentState(2);
    } catch (error) {
      console.error("Error al iniciar sesión con Google:", error);
    }
  }
  if (state === 1 || state === 2 || state === 3) {
    return (
      <section className="loading-container">
        <img src={ChatbotImage} className="login-loading"></img>
        </section>
    );
  }
  
  if (state === 4) {
    return (
      <section className="login-view-container">
        <section className="login-container">
          <h1 className="login-title">ASISTENTE MARKETING</h1>
          <img src={ChatbotImage} className="login-image"></img>
          <button className="login-button" onClick={handleOnClick}>
            Iniciar sesión con Google
          </button>
        </section>
      </section>
    );
  }
return null;
  
}
