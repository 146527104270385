import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  doc,
  updateDoc,
  getDocs,
  getDoc,
  query,
  where,
  deleteDoc,
  addDoc,
  serverTimestamp,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";

import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  getBytes,
} from "firebase/storage";

import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyB2WfB6rFv6LXrWaDf5u7A_hv_H-sq4kXE",
  authDomain: "asistente-virtual-empresarial.firebaseapp.com",
  projectId: "asistente-virtual-empresarial",
  storageBucket: "asistente-virtual-empresarial.appspot.com",
  messagingSenderId: "59125668031",
  appId: "1:59125668031:web:e44b99f0778c2756dc8e0a",
  measurementId: "G-GNTDEW77T7"
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

export function getCurrentUser() {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe(); // Una vez que se obtiene el usuario, se deja de escuchar cambios en la autenticación
      if (user) {
        resolve(user.uid); // Se resuelve la promesa con el UID del usuario actual
      } else {
        resolve(null); // Si no hay usuario, resolver con null
      }
    }, reject); // Se rechaza la promesa si hay algún error
  });
}
export async function userExists(uid) {
  try {
    const usersRef = collection(db, "usersChat");
    const queryRef = query(usersRef, where("uid", "==", uid));
    const res = await getDocs(queryRef);
    console.log("userExists?->", res);
    return res.docs.length > 0;
  } catch (error) {
    console.log(error);
  }
}
async function getUsername(uid) {
  try {
    const usersRef = collection(db, "usersChat");
    const queryRef = query(usersRef, where("uid", "==", uid));
    const res = await getDocs(queryRef);
    if (!res.empty) {
      return res.docs[0].data().displayName;
    }
  } catch (error) {
    console.log(error);
  }
  return ""; // En caso de error, devuelve una cadena vacía
}

export async function registerUser(user) {
  try {
    const { uid, displayName, email } = user;

    // Crear un objeto de usuario para almacenar en Firestore
    const userData = {
      uid,
      displayName,
      email,
      // Agrega cualquier otra información relevante que desees almacenar en Firestore
    };
    const collectionRef = collection(db, "usersChat");
    await addDoc(collectionRef, userData);
    console.log("Usuario registrado");
  } catch (error) {
    console.log(error);
  }
}
export async function createMessage(user, discussionId, prompt, checked) {
  const messagesRef = collection(
    db,
    "chats",
    user,
    "discusiones",
    discussionId,
    "messages"
  );
  console.log("checked firebase->"+checked);
  const contextPrompt=`&firstString=La respuesta debe ser formal, extenso y basado en evidencia científica. Utiliza un tono informativo y persuasivo para involucrar al lector en la temática. Asegúrate de citar fuentes y referencias donde sea necesario.Trata de retornar minimo 1000 palabras.&endString.${prompt}`;
  console.log("promptDocument->"+contextPrompt);

  const message = {
    prompt: checked ? contextPrompt : prompt,
    checked: checked,
  };

  try {
    const newDocRef = await addDoc(messagesRef, message);
    console.log("Mensaje guardado con ID: ", newDocRef.id);
    return newDocRef.id;
  } catch (error) {
    console.error("Error al guardar el mensaje: ", error);
  }
}
/* 
export async function getDiscussionId(user) {
  const userRef = doc(db, "chats", user);
  const discussionRef = collection(userRef, "discusiones");

  const querySnapshot = await getDocs(discussionRef);
  if (!querySnapshot.empty) {
    console.log("idDiscussion:", querySnapshot.docs[0].id);
    return querySnapshot.docs[0].id;
  } else {
    const userExistsResult = await userExists(user); 
    if (userExistsResult) {
      const newDiscussionRef = await addDoc(discussionRef, {
        id: serverTimestamp()
      });

      
      console.log("id-> ", newDiscussionRef);
      return newDiscussionRef.id;
    } else {
      const newDocRef = await addDoc(discussionRef, {
        id: serverTimestamp()
      });
      console.log("id-> ", newDocRef);
      return newDocRef.id;
    }
  }
} */

export async function createNewDiscussion(user) {
  const userRef = doc(db, "chats", user);
  const discussionRef = collection(userRef, "discusiones");
  const userExistsResult = await userExists(user);
  console.log(userExistsResult);
  if (userExistsResult) {
    const username = await getUsername(user);
    const message = `Hola Marbelis. ¿En qué puedo ayudarte?`;

    const newDocRef = await addDoc(discussionRef, {
      id: serverTimestamp(),
    });

    const messagesRef = collection(
      db,
      "chats",
      user,
      "discusiones",
      newDocRef.id,
      "messages"
    );
    await addDoc(messagesRef, {
      response: message,
      createTime: serverTimestamp(),
      status: { state: "COMPLETED" },
    });
    console.log("id-> ", newDocRef);
    return newDocRef.id;
  }
}

export async function getDiscussionIds(user) {
  try {
    const userRef = doc(db, "chats", user);
    const discussionRef = collection(userRef, "discusiones");

    const querySnapshot = await getDocs(
      query(discussionRef, orderBy("id", "asc"))
    );

    if (!querySnapshot.empty) {
      const discussionIds = querySnapshot.docs.map((doc) => doc.id);
      console.log("discussionsId lenght->" + discussionIds.length);
      if (discussionIds.length >= 2) {
        const lastTwoDiscussions = discussionIds.slice(0, 2);
        const [lastDiscussionId, secondLastDiscussionId] = lastTwoDiscussions;

        const lastDiscussionDoc = await getDoc(
          doc(discussionRef, lastDiscussionId)
        );
        const secondLastDiscussionDoc = await getDoc(
          doc(discussionRef, secondLastDiscussionId)
        );

        const lastDate = lastDiscussionDoc.data().id;
        const secondLastDate = secondLastDiscussionDoc.data().id;

        console.log("lastDate->", lastDate);
        console.log("secondlastDate->", secondLastDate);

        const date1 = new Date(
          lastDate.seconds * 1000 + lastDate.nanoseconds / 1000000
        );
        const date2 = new Date(
          secondLastDate.seconds * 1000 + secondLastDate.nanoseconds / 1000000
        );

        const differenceInMilliseconds = Math.abs(date2 - date1);
        const differenceInSeconds = differenceInMilliseconds / 1000;

        console.log("Diferencia en milisegundos:", differenceInMilliseconds);
        console.log("Diferencia en segundos:", differenceInSeconds);

        if (differenceInSeconds <= 0.7) {
          console.log("se duplican");
          // Si los dos últimos tienen la misma fecha, elimina uno de ellos
          console.log(
            "discussionRef->",
            discussionRef,
            "lasDiscussionId->",
            lastDiscussionId
          );
          await deleteDiscussion(user, lastDiscussionId);
          discussionIds.splice(discussionIds.indexOf(lastDiscussionId), 1); // Elimina la discusión duplicada del array
        }
      }
      return discussionIds;
    }
  } catch (error) {
    console.error("Error al obtener IDs de discusiones: ", error);
    return [];
  }
}

export async function getLastDiscussionId(user) {
  try {
    const userRef = doc(db, "chats", user);
    const discussionRef = collection(userRef, "discusiones");

    const querySnapshot = await getDocs(
      query(discussionRef, orderBy("id", "desc"), limit(1))
    );

    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].id;
    }
    return null;
  } catch (error) {
    console.error("Error al obtener la última discusión: ", error);
    return null;
  }
}

export async function deleteDiscussion(user, discussionId) {
  try {
    const discussionRef = doc(db, "chats", user, "discusiones", discussionId);

    // Elimina los mensajes dentro de la subcolección "messages"
    const messagesRef = collection(discussionRef, "messages");
    const messagesSnapshot = await getDocs(messagesRef);
    const deleteMessagePromises = messagesSnapshot.docs.map(async (doc) => {
      await deleteDoc(doc.ref);
    });
    await Promise.all(deleteMessagePromises);

    // Finalmente, elimina el documento principal
    await deleteDoc(discussionRef);

    console.log("Discusión y mensajes eliminados:", discussionId);
  } catch (error) {
    console.error("Error al eliminar la discusión y mensajes:", error);
    throw error;
  }
}
export async function uploadPDF(file, fileName, user) {
  try {
    const userStorageRef = ref(storage, `${user}/${fileName}`); // Crea una referencia al archivo en Storage dentro de la carpeta del usuario
    await uploadBytes(userStorageRef, file); // Crea una referencia al archivo en Storage
    console.log("Archivo subido exitosamente");
    const downloadURL = await getDownloadURL(userStorageRef); // Obtiene el enlace de descarga del archivo
    console.log("URL de descarga:", downloadURL);
    return downloadURL; // Retorna el enlace de descarga
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    throw error;
  }
}
export async function uploadDOCX(blob, fileName, user) {
  try {
    const userStorageRef = ref(storage, `${user}/${fileName}`); // Crea una referencia al archivo en Storage dentro de la carpeta del usuario
    await uploadBytes(userStorageRef, blob); // Sube el archivo a Storage
    console.log("Archivo subido exitosamente");
    const downloadURL = await getDownloadURL(userStorageRef); // Obtiene el enlace de descarga del archivo
    console.log("URL de descarga:", downloadURL);
    return downloadURL; // Retorna el enlace de descarga
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    throw error;
  }
}
export async function documentResponseGenerate(discussionId, messageId, user) {
  const messageRef = doc(
    db,
    "chats",
    user,
    "discusiones",
    discussionId,
    "messages",
    messageId
  );
  console.log("entre documentResponse " + messageRef);
  try {
    const messageDoc = await getDoc(messageRef); // Obtener el documento
    console.log("Obtuve el documento:", messageDoc);

    console.log("message exist? " + messageDoc.exists());
    if (messageDoc.exists()) {
      const messageData = messageDoc.data();
      if (
        messageData &&
        messageData.status &&
        messageData.status.state === "COMPLETED" &&
        messageData.response
      ) {
        console.log("messageData " + messageData.response);
        return messageData.response;
      } else {
        // Si el estado del mensaje no es "COMPLETED" o no hay respuesta, esperar a que el estado cambie
        return new Promise((resolve, reject) => {
          const unsubscribe = onSnapshot(messageRef, (doc) => {
            const messageData = doc.data();
            console.log("else antes del if");

            if (
              messageData &&
              messageData.status &&
              messageData.status.state === "COMPLETED" &&
              messageData.response
            ) {
              console.log("else if message");
              console.log("messageData->", messageData);
              console.log("messageData.status->", messageData.status);
              console.log(
                "messageData.status.state->",
                messageData.status.state
              );
              console.log("messageData.response->", messageData.response);
              console.log(
                "messageData.response tipo->",
                typeof messageData.response
              );
              unsubscribe(); // Detener la escucha del snapshot
              console.log("antes del resolve");
              resolve(messageData.response);
              console.log("parece que funciono");
            }
          });
        });
      }
    }
    console.log("F");
  } catch (error) {
    console.error("Error al obtener el mensaje:", error);
  }
}
export async function callCreateDocumentFunction(texto) {
  try {
    // Obtén una referencia a la función alojada en Firebase Functions
    const createDocument = httpsCallable(functions, "createDocument");

    // Llama a la función y pasa los parámetros necesarios
    const result = await createDocument({ texto });

    // Maneja la respuesta si es necesario
    console.log("Resultado de la función:", result);
    return result;
  } catch (error) {
    // Maneja los errores
    console.error("Error al llamar a la función:", error);
  }
}
export async function createMessageDocument(user, discussionId, messageId, url) {
  try {
    // Referencia al documento específico
    const messageDocRef = doc(db, "chats", user, "discusiones", discussionId, "messages", messageId);

    const messageUpdate = {
      document: `Documento generado: ${url}`,
      createTime: serverTimestamp(),
      // Agrega aquí cualquier otro campo que desees incluir o actualizar
    };
      await updateDoc(messageDocRef, {
        document: `Documento generado: ${url}`
        // Puedes añadir más campos aquí si lo deseas
      });
      console.log("Campo añadido exitosamente");
  } catch (error) {
    console.error("Error al crear o actualizar el documento:", error);
    throw error;
  }
}