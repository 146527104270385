import { Document, Packer, Paragraph, TextRun, HeadingLevel, AlignmentType, Numbering } from "docx";

export const generateWordDocumentFormatted = (texto) => {
  const lines = texto.split("\n");
  let currentNumberingList = null; 
  const doc = new Document({
    sections: [
      {
        children: lines.map((line, index) => {
          if (line.startsWith("**") && index===0) {
            // Título en general
            return new Paragraph({
              children: [new TextRun(line.replace(/\*\*/g, ""))],
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
            });
          } else if (line.startsWith("**")) {
            // Subtítulo
            return new Paragraph({
              children: [new TextRun(line.replace(/\*/g, ""))],
              heading: HeadingLevel.HEADING_2, // Cambiado a HEADING_2 para subtitulos
            });
          } /*else if (line.startsWith("* ")) {
            // Elemento de lista
            if (!currentNumberingList) {
              currentNumberingList = new Numbering({
                config: [
                  {
                    reference: "list",
                    levels: [
                      {
                        level: 0,
                        format: "bullet",
                      },
                    ],
                  },
                ],
              });
              doc.addNumbering(currentNumberingList);
            }
            return new Paragraph({
              children: [new TextRun(line.replace(/·/g, ""))],
              numbering: { reference: "list", level: 0 },
            });
          } */else {
            // Texto normal
            return new Paragraph(line);
          }
        }),
      },
    ],
  });

  console.log("generado");
  return Packer.toBlob(doc);
};
