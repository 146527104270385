import { BrowserRouter, Route, Routes } from "react-router-dom";

import { pathRoutes } from "./PathRoutes";
import Home from "../pages/Home";
import ChatBot from "../pages/ChatBot";


function WebRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={pathRoutes.home} Component={Home}></Route>
        <Route path={pathRoutes.chatBot} Component={ChatBot}></Route>
      </Routes>
    </BrowserRouter>
  );
}
export default WebRoutes;
