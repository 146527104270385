/* import React, { useEffect, useState } from "react";
import ChatBotTest from "../components/ChatBot/ChatBotTest";
import { getCurrentUser } from "../firebase/firebaseConfig";

function ChatBotPage() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await getCurrentUser();
        console.log("user-> "+user);
        setCurrentUser(user);
      } catch (error) {
        console.error("Error al obtener el usuario actual:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCurrentUser();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Cargando usuario...</p>
      ) : (
        <ChatBotTest user={currentUser} />
      )}
    </div>
  );
}

export default ChatBotPage;
 */
import React, { useEffect, useState } from "react";
import ChatBotTest from "../components/ChatBot/ChatBotTest";
import { useLocation } from "react-router-dom";
function ChatBotPage() {
  const location = useLocation();
  const { userId } = location.state || {};

  return (
    <div>
      
        <ChatBotTest user={userId} />
      
    </div>
  );
}

export default ChatBotPage;
