import React, { useEffect, useState, useRef } from "react";
import {
  onSnapshot,
  query,
  collection,
  orderBy,
  getDocs,
} from "firebase/firestore";
import {
  auth,
  db,
  createMessage,
  createNewDiscussion,
  getDiscussionIds,
  getLastDiscussionId,
  deleteDiscussion,
  uploadPDF,
  uploadDOCX,
  documentResponseGenerate,
  createMessageDocument,
} from "../../firebase/firebaseConfig";
import "./ChatBotTest.css";
import { useNavigate } from "react-router-dom";
import { pathRoutes } from "../../routes/PathRoutes";
import { extractTextFromPDF } from "../DocumentCreator/PdfExtractor";
import Checkbox from "@mui/material/Checkbox";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Document, Packer, Paragraph, HeadingLevel } from "docx";
import { generateWordDocumentFormatted } from "../DocumentCreator/DocumentGenerator";
//import { saveAs } from "file-saver";

export default function ChatBotTest({ user }) {
  const [messages, setMessages] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [loading, setLoading] = useState(false);
  const messagesContainerRef = useRef(null);
  const navigate = useNavigate();
  const [selectedDiscussion, setSelectedDiscussion] = useState(null);
  const [discussionIds, setDiscussionIds] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isCreatingDiscussion, setIsCreatingDiscussion] = useState(false);
  const [pdfFile, setPdfFile] = useState(undefined);
  const [fileName, setFileName] = useState(undefined);
  const [filePath, setFilePath] = useState(undefined);
  const [vacio, setVacio] = useState(true);
  const [extractedText, setExtractedText] = useState("");
  const [checked, setChecked] = useState(false);
  const [responseText, setResponseText] = useState("");

  console.log(selectedDiscussion);
  console.log("user-> " + user);
  console.log("extractedText-> " + extractedText);
  console.log("nombre pdf-> " + fileName);
  console.log("path pdf-> " + filePath);
  console.log("vacio-> " + vacio);
  console.log("checked-> " + checked);
  console.log("lastResponse->" + responseText);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const path = URL.createObjectURL(file);
    setPdfFile(file);
    setFileName(file.name);
    setFilePath(path);
  };
  const generateWordDocument = (texto) => {
    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: texto,
              heading: HeadingLevel.HEADING_1,
            }), // Elimina la coma al final de este objeto
          ],
        },
      ],
    });
    console.log("generado");
    /* Packer.toBlob(doc).then((blob) => {
      console.log(blob);
      saveAs(blob, "example.docx");
      console.log("Document created successfully");
    }); */

    return Packer.toBlob(doc);
  };

  const handleChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleDiscussionSelection = async (discussionId) => {
    setSelectedDiscussion(discussionId);
    const messagesRef = collection(
      db,
      "chats",
      user,
      "discusiones",
      discussionId,
      "messages"
    );
    const messageQuery = query(messagesRef, orderBy("createTime", "asc"));

    try {
      const unsubscribe = onSnapshot(messageQuery, (snapshot) => {
        const messageData = snapshot.docs.map((doc) => doc.data());
        console.log("Mensajes con fecha:", messageData);
        console.log("Usuario:", user);
        setMessages(messageData);
      });
      setSidebarOpen(false);
      return () => {
        // Limpia la suscripción cuando el componente se desmonta o cambia de discusión
        unsubscribe();
      };
    } catch (error) {
      console.error("Error al obtener mensajes de la discusión:", error);
    }
  };
  const handleNewDiscussion = async () => {
    try {
      setIsCreatingDiscussion(true);
      const newDiscution = await createNewDiscussion(user);
      const ids = await getDiscussionIds(user);
      setDiscussionIds(ids);
      handleDiscussionSelection(newDiscution);
    } catch (error) {
      console.error("Error al crear una nueva discusión:", error);
    } finally {
      setIsCreatingDiscussion(false);
    }
  };
  const handleDeleteDiscussion = async (event, discussionId) => {
    event.stopPropagation(); // Evita que se propague el evento a la selección de la discusión
    try {
      // Lógica para eliminar la discusión
      await deleteDiscussion(user, discussionId);
      const updatedDiscussionIds = discussionIds.filter(
        (id) => id !== discussionId
      );
      setDiscussionIds(updatedDiscussionIds);
      if (selectedDiscussion === discussionId) {
        const lastDiscussionId = await getLastDiscussionId(user);
        setSelectedDiscussion(lastDiscussionId); // Desselecciona la discusión si es la seleccionada
      }
    } catch (error) {
      console.error("Error al eliminar la discusión:", error);
    }
  };

  useEffect(() => {
    const fetchDiscussion = async () => {
      const lastDiscussionId = await getLastDiscussionId(user);

      if (!lastDiscussionId) {
        const newDiscussionId = await createNewDiscussion(user);
        setSelectedDiscussion(newDiscussionId);
        setDiscussionIds([newDiscussionId]);
        return;
      }

      setSelectedDiscussion(lastDiscussionId);
      const ids = await getDiscussionIds(user);
      setDiscussionIds(ids instanceof Array ? ids : [ids]);
    };
    if (user) {
      fetchDiscussion();
    }
    //fetchDiscussion();
  }, [user]);

  useEffect(() => {
    if (user && selectedDiscussion) {
      const messagesRef = collection(
        db,
        "chats",
        user,
        "discusiones",
        selectedDiscussion,
        "messages"
      );
      const messageQuery = query(messagesRef, orderBy("createTime", "asc"));

      const unsubscribe = onSnapshot(messageQuery, (snapshot) => {
        const messageData = snapshot.docs.map((doc) => doc.data());
        console.log("Mensajes con fecha:", messageData);
        console.log("Usuario:", user);
        setMessages(messageData);
      });
      return () => {
        unsubscribe();
      };
    }
  }, [selectedDiscussion, user]);

  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [messages, prompt]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (prompt.trim() === "" || loading || !selectedDiscussion) {
      return;
    }
    const formattedPrompt = prompt.charAt(0).toUpperCase() + prompt.slice(1);
    if (pdfFile) {
      try {
        console.log("dentro de si existe pdf file");
        const downloadURL = await uploadPDF(pdfFile, fileName, user);
        console.log("downloadUrl->" + downloadURL);
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const pdfArrayBuffer = fileReader.result; // Blob or ArrayBuffer
          try {
            const text = await extractTextFromPDF(pdfArrayBuffer);
            setExtractedText(text);
            console.log("textoEE->" + text);
            console.log("tipo de texto-> " + typeof text);
            console.log("longitudEE->" + text.length);
            const promptWithPDF = `&firstString=${text}&endString.${formattedPrompt}.\n Url archivo adjuntado: ${downloadURL} . `;
            const discussionId = selectedDiscussion;
            const createTime = new Date();
            const newMessage = {
              prompt: promptWithPDF,
              createTime,
              status: { state: "PROCESSING" },
            };
            setMessages([...messages, newMessage]);
            const messageId = await createMessage(
              user,
              discussionId,
              promptWithPDF,
              checked
            ); // Guarda el ID del mensaje
            setMessages([...messages, newMessage]);
            setPrompt("");

            if (checked) {
              const lastResponse = await documentResponseGenerate(
                selectedDiscussion,
                messageId,
                user
              );
              console.log("funcion lastResponse->", lastResponse);
              console.log("funcion typeof lastResponse->", typeof lastResponse);
              setResponseText(lastResponse);
              //const lastResponseFormatted = formatResponse(lastResponse);
              //console.log("formatedResponse->" + lastResponseFormatted);
              console.log("responseText->" + responseText);
              if (lastResponse) {
                console.log("entramosexito");
                const blob = await generateWordDocumentFormatted(lastResponse);
                const fileName = `${messageId}_generated.docx`;
                console.log("blobCreado->" + fileName);
                try {
                  const downloadURL = await uploadDOCX(blob, fileName, user);
                  console.log("URL de descarga:", downloadURL);
                  console.log("parece que se genero bien...");
                  await createMessageDocument(
                    user,
                    discussionId,
                    messageId,
                    downloadURL
                  );
                } catch (error) {
                  console.error("Error al subir el archivo:", error);
                }
              }
            }
            setLoading(false);
          } catch (error) {
            console.error("Error al extraer texto del PDF:", error);
          }
        };
        fileReader.readAsArrayBuffer(pdfFile);
        setFilePath(undefined);
        setFileName(undefined);
        setPdfFile(undefined);
      } catch (error) {
        console.error("Error al adjuntar el PDF y enviar el mensaje:", error);
      }
    } else {
      setLoading(true);
      const data = new FormData(event.target);
      const newPrompt = data.get("prompt");
      setPrompt(newPrompt);

      try {
        const discussionId = selectedDiscussion; // Utiliza el ID de la discusión seleccionada
        const createTime = new Date();
        const newMessage = {
          prompt: formattedPrompt,
          createTime,
          status: { state: "PROCESSING" },
        };
        console.log("Createchecked" + checked);
        const messageId = await createMessage(
          user,
          discussionId,
          newPrompt,
          checked
        ); // Guarda el ID del mensaje
        setMessages([...messages, newMessage]);
        setPrompt("");

        // Verificar si el checkbox está marcado
        if (checked) {
          console.log("entre if cheked");
          const lastResponse = await documentResponseGenerate(
            selectedDiscussion,
            messageId,
            user
          );
          console.log("funcion lastResponse->", lastResponse);
          console.log("funcion typeof lastResponse->", typeof lastResponse);
          setResponseText(lastResponse);
          //const lastResponseFormatted = formatResponse(lastResponse);
          //console.log("formatedResponse->" + lastResponseFormatted);
          console.log("responseText->" + responseText);
          if (lastResponse) {
            console.log("entramosexito");
            //await createDocument(messageId, lastResponse);
            const blob = await generateWordDocumentFormatted(lastResponse);

            //const blob = await callCreateDocumentFunction(lastResponse);
            //console.log("blob->"+blob);

            const fileName = `${messageId}_generated.docx`;
            console.log("blobCreado->" + fileName);
            try {
              const downloadURL = await uploadDOCX(blob, fileName, user);
              console.log("URL de descarga:", downloadURL);
              console.log("parece que se genero bien...");
              await createMessageDocument(
                user,
                discussionId,
                messageId,
                downloadURL
              );
            } catch (error) {
              console.error("Error al subir el archivo:", error);
            }
          }
        }
        //setResponseText("");
        setLoading(false);
      } catch (error) {
        console.error("Error al enviar el mensaje:", error);
        setLoading(false);
      }
    }
  };

  const handleOut = async () => {
    setSidebarOpen(false);
  };
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      navigate(pathRoutes.home);
    } catch (error) {
      console.error("Error al cerrar sesión: ", error);
    }
  };

  const formatResponse = (text) => {
    const paragraphs = text.split("\n").map((paragraph, index) => (
      <div key={index}>
        {paragraph.split(/\*\*(.*?)\*\*/g).map((part, i) => {
          if (i % 2 === 1) {
            // Resaltar el texto entre ** **
            return <strong key={`${index}-${i}`}>{part}</strong>;
          } else {
            return <span key={`${index}-${i}`}>{part}</span>;
          }
        })}
      </div>
    ));

    return paragraphs;
  };

  const extraerTextoDespuesEndString = (prompt) => {
    const endStringPos = prompt.lastIndexOf("&endString.");
    if (endStringPos !== -1) {
      // Si &endString. está presente, retorna solo la parte después de él
      return prompt.substring(endStringPos + "&endString.".length);
    } else {
      // Si &endString. no está presente, retorna el prompt completo
      return prompt;
    }
  };
  const formatPrompt = (prompt) => {
    // Convertir enlaces de Firebase Storage en enlaces clicables
    const storageLinkRegex =
      /(https:\/\/firebasestorage\.googleapis\.com\/[^\s]+)/g;
    const partsWithLinks = prompt.split(storageLinkRegex).map((part, index) => {
      if (index % 2 === 1) {
        // Es un enlace de Firebase Storage, convertirlo en un enlace clicable
        return (
          <a
            className="chatbot-firebase-link"
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
    return <div className="prompt">{partsWithLinks}</div>;
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <section className="chat-container">
      <aside className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <section className="discussion-section">
          <section className="menu-sidebar">
            <button className="out-sidebar-button" onClick={handleOut}>
              {" "}
              {"<"}
            </button>
            <button className="chatbot-signout" onClick={handleSignOut}>
              Cerrar sesión
            </button>
          </section>
        </section>

        <button
          className="add-discussion-button"
          onClick={handleNewDiscussion}
          disabled={isCreatingDiscussion}
        >
          {" "}
          + Nueva discusión
        </button>
        <div className="discussion-list">
          {discussionIds.map((discussionId, index) => (
            <div
              className="discusionId-item"
              key={index}
              onClick={() => handleDiscussionSelection(discussionId)}
            >
              {/* {
              discussionId && (
              <div >{discussionId}</div>
            )} */}
              Discusión {index + 1}
              <button
                className="delete-discussion-button"
                onClick={(e) => handleDeleteDiscussion(e, discussionId)}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </aside>

      <section className="main-content">
        <section className="chat-title-container">
          <h1 className="chatbot-title">ASISTENTE MARKETING</h1>
          <button className="sidebar-toggle-btn" onClick={toggleSidebar}>
            ☰
          </button>
        </section>
        <section className="chatbot-container" ref={messagesContainerRef}>
          <div className="chatbot-response" id="message-container">
            {messages.map((message, index) => {
              console.log("Mensaje:", message);
              return (
                <div
                  key={index}
                  className={`chat-message ${
                    message.status === "prompt"
                      ? "chat-prompt"
                      : "chat-response"
                  }`}
                >
                  {message.prompt && (
                    <div className="chat-prompt-message">
                      {formatPrompt(
                        extraerTextoDespuesEndString(message.prompt)
                      )}
                    </div>
                  )}
                  {message.prompt && (
                    <div className="message-date-prompt">
                      {message.createTime instanceof Date
                        ? message.createTime.toLocaleString()
                        : message.createTime.toDate().toLocaleString()}
                    </div>
                  )}
                  {message.status && message.status.state === "PROCESSING" && (
                    <div className="loading-indicator">
                      <h1>Cargando...</h1>
                    </div>
                  )}
                  {!message.checked &&
                    message.status &&
                    message.status.state === "COMPLETED" &&
                    message.response && (
                      <div className="chat-response-message">
                        {formatResponse(message.response)}
                      </div>
                    )}
                  {!message.checked &&
                    message.status &&
                    message.status.state === "COMPLETED" &&
                    message.response && (
                      <div className="message-date-response">
                        {message.createTime.toDate().toLocaleString()}
                      </div>
                    )}
                  {message.document && (
                    <div className="chat-response-message">
                      {formatPrompt(message.document)}
                    </div>
                  )}
                  {message.document && (
                    <div className="message-date-response">
                      {message.createTime instanceof Date
                        ? message.createTime.toLocaleString()
                        : message.createTime.toDate().toLocaleString()}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </section>
        <form onSubmit={handleSubmit} className="chatbot-form">
           {/* <div className="checkbox-p">
            <Checkbox
              checked={checked}
              icon={<NoteAddOutlinedIcon />} // Icono cuando no está marcado
              checkedIcon={<NoteAddIcon />}
              onChange={handleChecked}
              inputProps={{ "aria-label": "controlled" }}
            />
            <p>GENERAR DOCUMENTO</p>
          </div> */}
          <textarea
            name="prompt"
            placeholder="Escribe tu mensaje..."
            className="chatbot-prompt-input"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
          <button
            type="submit"
            className="chatbot-submit"
            disabled={!prompt.trim() || loading || !selectedDiscussion}
          >
            <span className="chatbot-text">
              {loading ? "Enviando..." : "Enviar"}
            </span>
            <span className="chatbot-icon material-symbols-outlined">send</span>
          </button>
          <div className="button-file">
            <input
              type="file"
              id="uploadBtn"
              onChange={handleFileSelect}
              accept="application/pdf"
            />

            <label htmlFor="uploadBtn" className="uploadBtn">
            <span className="material-symbols-outlined btn-icon">
                file_upload
              </span>
              <p>{fileName ? fileName : 'Subir archivo'}</p>
            </label>
          </div>
          {/* {pdfFile && (
            <embed
              src={URL.createObjectURL(pdfFile)}
              type="application/pdf"
              width="100%"
              height="100px"
            />
          )} */}
          {/*   <Document file={pdfFile}>
            <Page pageNumber={1} />
          </Document> */}

          {/* {extractedText && <p>{extractedText}</p>} */}
        </form>
      </section>
    </section>
  );
}
